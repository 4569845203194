
import { settingsStateMapGetter } from '@/services/settingsService'
import { userAuthStateMapGetter } from '@/services/userService'
import { getMetaTags } from '@/utils'
import PageTitle from '@/components/PageTitle'
import SocialLinks from '@/components/home/SocialLinks'
import TopTenUserteams from '@/components/home/TopTenUserteams'
import TopTenPools from '@/components/home/TopTenPools'
import TopTenRankinguserteams from '@/components/home/TopTenRankinguserteams'
import TopTenRankingpools from '@/components/home/TopTenRankingpools'
import NextStage from '@/components/home/NextStage'
import Results from '@/components/home/Results'
import News from '@/components/home/News'

export default {
  nuxtI18n: {
    paths: {
      en: '/',
      nl: '/',
      it: '/',
      de: '/',
      fr: '/',
      es: '/'
    }
  },
  components: {
    PageTitle,
    SocialLinks,
    TopTenUserteams,
    TopTenPools,
    TopTenRankinguserteams,
    TopTenRankingpools,
    NextStage,
    Results,
    News
  },
  computed: {
    ...settingsStateMapGetter(),
    ...userAuthStateMapGetter(),
    getPooltype() {
      if (this.settings.pooltype.name_locale_plural) {
        return (
          this.$t(this.settings.pooltype.name_locale_plural) +
          ' / ' +
          this.$t('page_home_top10')
        )
      }

      return this.$t('page_home_pools_top10')
    }
  },
  methods: {
    getPageUrl() {
      const domain = this.settings.domain.name
      return `https://${domain}${this.$route.path}`
    },
    getSelectedRaceFullName() {
      return this.selectedRace.name + ' ' + this.selectedRace.year
    },
    getTargetCountry() {
      return this.settings.domain.target_country
    }
  },
  head() {
    const title = this.$t('page_home_seo_title', {
      country: this.$t(this.getTargetCountry())
    })
    const description = this.$t('page_home_seo_description', {
      race: this.getSelectedRaceFullName(),
      country: this.$t(this.getTargetCountry())
    })
    const pageUrl = this.getPageUrl()
    const settings = this.settings

    return getMetaTags(title, description, pageUrl, settings)
  }
}
