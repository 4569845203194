
import { settingsStateMapGetter } from '@/services/settingsService'
import { withCatch } from '@/utils'
import ApiStatusPending from '@/components/home/ApiStatusPending'
import ApiStatusError from '@/components/home/ApiStatusError'

export default {
  components: {
    ApiStatusPending,
    ApiStatusError
  },
  data() {
    return {
      raceRankinguserteamsTopTen: [],
      isEmpty: false,
      hasMobileCards: false,
      api_status: 'PENDING'
    }
  },
  computed: {
    ...settingsStateMapGetter()
  },
  created() {
    this.loadAsyncData(this.app)
  },
  methods: {
    async loadAsyncData(app) {
      const { response: res, error: err } = await withCatch(() =>
        this.$raceApi.getRankinguserteamsTopTen(
          this.selectedRace.slug,
          this.selectedRace.current_stage_number
        )
      )

      if (err) {
        this.$sentry.captureException(err)
        this.api_status = 'ERROR'
        return
      }

      const { data } = res

      if (data.length === 0) {
        this.isEmpty = true
      } else {
        this.raceRankinguserteamsTopTen = data
      }

      this.api_status = 'SUCCESS'
    }
  }
}
