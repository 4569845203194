
import { settingsStateMapGetter } from '@/services/settingsService'

export default {
  computed: {
    ...settingsStateMapGetter()
  },
  methods: {
    openLink(link) {
      window.open(link, '_blank')
    }
  }
}
