
import ApiStatusPending from '@/components/home/ApiStatusPending'
import ApiStatusError from '@/components/home/ApiStatusError'
import { settingsStateMapGetter } from '@/services/settingsService'
import { withCatch } from '@/utils'

export default {
  components: {
    ApiStatusPending,
    ApiStatusError
  },
  data() {
    return {
      poolsTopTen: [],
      isEmpty: false,
      hasMobileCards: false,
      api_status: 'PENDING'
    }
  },
  computed: {
    ...settingsStateMapGetter(),
    getPooltype() {
      if (this.settings.pooltype.name_locale_plural) {
        return this.$t('page_home_link_pooltypes_text', {
          pooltype: this.$t(this.settings.pooltype.name_locale_plural)
        })
      }

      return this.$t('page_home_link_pools_text')
    },
    getTableHeaderPooltype() {
      if (this.settings.pooltype.name_locale_singular) {
        return this.$t(this.settings.pooltype.name_locale_singular)
      }

      return this.$t('table_th_pool')
    }
  },
  created() {
    this.loadAsyncData()
  },
  methods: {
    async loadAsyncData() {
      const { response: res, error: err } = await withCatch(() =>
        this.$raceApi.getPoolsTopTen(this.selectedRace.slug)
      )

      if (err) {
        this.$sentry.captureException(err)
        this.api_status = 'ERROR'
        return
      }

      const { data } = res

      const poolsTopTen = []

      if (data.length === 0) {
        this.isEmpty = true
      } else {
        data.forEach((item, index) => {
          item.position = index + 1
          item.points = 0
          poolsTopTen.push(item)
        })
      }

      this.poolsTopTen = poolsTopTen
      this.api_status = 'SUCCESS'
    }
  }
}
