
import { settingsStateMapGetter } from '@/services/settingsService'
import { withCatch } from '@/utils'
import ApiStatusPending from '@/components/home/ApiStatusPending'
import ApiStatusError from '@/components/home/ApiStatusError'

export default {
  components: {
    ApiStatusPending,
    ApiStatusError
  },
  data() {
    return {
      userteamsTopTen: [],
      isEmpty: false,
      hasMobileCards: false,
      api_status: 'PENDING'
    }
  },
  computed: {
    ...settingsStateMapGetter()
  },
  created() {
    this.loadAsyncData(this.app)
  },
  methods: {
    async loadAsyncData(app) {
      const { response: res, error: err } = await withCatch(() =>
        this.$raceApi.getUserteamsTopTen(this.selectedRace.slug)
      )

      if (err) {
        this.$sentry.captureException(err)
        this.api_status = 'ERROR'
        return
      }

      const { data } = res

      const userteamsTopTen = []

      if (data.length === 0) {
        this.isEmpty = true
      } else {
        data.forEach((item, index) => {
          item.position = index + 1
          item.points = 0
          userteamsTopTen.push(item)
        })
      }

      this.userteamsTopTen = userteamsTopTen
      this.api_status = 'SUCCESS'
    }
  }
}
