
export default {
  props: {
    classes: {
      type: String,
      default: '',
      required: false
    }
  }
}
